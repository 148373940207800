//
// user-variables.scss
// Use this to overwrite Bootstrap and Geeks variables
//
// Example of a variable override to change Geeks primary color
// Remove the "//" to comment it in and see it in action!
// $primary: #000;

.my-radio input[type='radio']:checked + .radio-button {
  background-color: var(--active-color);
  border-color: var(--active-color);
}
